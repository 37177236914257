<template>
    <div>
        <h1>Поздравляем!</h1>
        <p>
            Вы присоединились к тусовке садоводов-огородников Умный Огород.
        </p>

        <b>Ваш уровень: 1</b>
        <p>
            <b>Теперь вам доступны разделы:</b>
            <ul>
                <li>Избранное</li>
                <li>Рекорды</li>
                <li>Задания (в разработке)</li>
            </ul>
        </p>

        <p>
            <b>
                Вы можете отключить в настройках отображение рекламы.
            </b>
        </p>
    </div>
</template>